@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.ant-input-group-addon {
  background: #f1b807 !important;
}

.ant-input-wrapper {
  height: 50px !important;
}

.ant-input-lg {
  height: 50px !important;
  border: 1px solid #f1b807 !important;
  outline: none;
  box-shadow: none !important;
}

.admin-bg {
  background: linear-gradient(
    to right,
    #fef8e6 0%,
    #fef8e6 52%,
    #fbe9b2 52%,
    #fbe9b2 100%
  );
}

.admin-bg-hor {
  background: linear-gradient(
    to top,
    #fef8e6 0%,
    #fef8e6 50%,
    #fbe9b2 50%,
    #fbe9b2 100%
  );
}

.absoluteCentreY {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
