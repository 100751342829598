//
// Footer
//

.footer {
  padding: 1rem;
  background: url("../../images/src/footerShape.png") center center no-repeat,
    $footer-bg !important;
  display: grid;
  grid-template-columns: 1fr;
  color: $white;
  @media screen and (min-width: 992px) {
    padding: 2rem;
    gap: 4rem;
  }

  .social-icons-footer {
    display: grid;
    margin-top: 2rem;
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
    width: 15rem;
    align-items: center;
    font-size: 1rem;
    color: var(--clr-primary-5);
    transition: var(--transition);

    @media screen and (min-width: 992px) {
      display: grid;
      margin-top: -4rem;
      // width: 20rem;
      padding: 0;
    }
  }
}

.link-text {
  text-decoration: none;
  color: $white;
}
.footer-container {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 992px) {
    grid-template-columns: 40% 1fr 1fr 1fr;
    grid-gap: 2rem;
  }

  .footer-message {
    .message {
      margin-top: 34.54px;
      margin-bottom: 41.91px;
    }
  }
}

.footer-center {
  display: block;
  place-items: center;
  margin-left: 2rem;
  @media screen and (max-width: 540px) {
    margin-left: unset;
  }
}

.footer .social-icons {
  margin-bottom: 2rem;
}

.footer .social-icon {
  color: var(--clr-primary-10);
}

// .footer p {
//   font-size: 1.25rem;
//   text-transform: capitalize;
// }

.footer-quick-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150.5%;
  /* identical to box height, or 27px */

  color: $yellow;
}

.footer-quick-link-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.457rem;
  color: #ffffff;
}

.footer-bottom {
  display: flex;
  border-top: 2px dashed rgba(213, 213, 213, 0.2);
  padding: 2rem 0;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .footer-store {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.688rem;
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
}

.footer-bottom-div {
  // display: grid;
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff !important;
}
