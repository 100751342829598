//
// Layout Initialization
//

// Import Dependencies
@import "footer";
@import "header";

@import "authHeader";
@import "sideBar";
@import "authenticated";


.app-container {
  width: 100vw;
  background: $white;
  min-height: 100vh;
}

