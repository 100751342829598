//
//header
//

.layout-dark-container {
  height: 458px !important;

  @media screen and (max-width: 991px) {
    margin-top: -10px;
    height: 50vh !important;
  }
}
.imgLeft {
  width: 168.995px;
  height: 162.68px;

  @media screen and (max-width: 991px) {
    width: 20%;
    height: 20%;
  }
}

.imgRight {
  width: 205.998px;
  height: 180.34px;
  @media screen and (max-width: 991px) {
    width: 25%;
    height: 25%;
  }
}

.imgRight2 {
  // height: 88px;
  margin-top: -105px;
  @media screen and (max-width: 991px) {
    width: 50%;
    height: 50%;
  }
}

.header-logo {
  margin-left: 73px;
  margin-right: 120px;
  margin-bottom: 71px;

  @media screen and (max-width: 991px) {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 15%;
  }

  .logo {
    width: 162px;
    height: 39.01px;
    margin-top: 33px;

    @media screen and (max-width: 991px) {
      width: 100px;
      height: unset;
      margin-top: 24px;
    }
  }
  .home {
    width: 40px;
    height: 40px;
    margin-top: 28px;

    @media screen and (max-width: 991px) {
      width: 24px;
      height: 29px;
      margin-top: 24px;
    }
  }
}

.outletContent {
  margin: 5%;

  @media screen and (min-width: 992px) {
    margin-left: 73px;
    margin-right: 113px;
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
  }

  .header-container {
    min-height: 800px;
    position: relative;
  }

  .main {
    z-index: 1;
    @media screen and (min-width: 992px) {
      width: 539px;
    }
  }

  .bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .bottom-content-left {
    position: absolute;
    bottom: 0;
    left: -15%;
    width: 100%;
  }
}
.main-container {
  // border-radius: 40px;
  background: #fff;
  // box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  min-height: 50vh;
}

.nav {
  height: 5rem;
  padding: 1rem 0;
  // display: grid;
  // align-items: end;
  transition: var(--transition);
  background: transparent;
}

.nav-center {
  width: 100%;
  // max-width: 1440px;
  padding: 0rem 1rem;
  margin: 0 auto;
  z-index: 1;

  @media screen and (min-width: 992px) {
    // display: grid;
    // grid-template-columns: 1fr 2fr 1fr;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0;
  }
}

.nav-header {
  display: grid;
  grid-template-columns: auto;
  align-items: center;

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}

.hamburger-button {
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.bar {
  width: 30px;
  height: 4px;
  background: $white;
  margin: 6px 0;
  transition: 0.4s;
}

.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.nav-logo {
  width: 150px;
  height: 36px;
}

.nav-links {
  display: none;

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    white-space: nowrap;
    column-gap: 30px;
  }
}

.nav-button-container {
  display: none;
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    justify-self: center;
  }
}

@media screen and (min-width: 992px) {
  .nav-bar-margin-right {
    margin-right: 6rem;
  }

  .nav-bar-margin-left {
    margin-left: 6rem;
  }

  .nav-link-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    justify-self: center;
  }

  .nav-links a {
    text-transform: capitalize;
    transition: var(--transition);
    text-decoration: none;
    color: $white;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    display: inline-flex;
  }

  .nav-links a::after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: var(--clr-primary-5);
    margin: 0.3rem auto;
    display: grid;
  }

  .active::after {
    content: "";
    width: 100%;
    height: 3px;
    margin: 0.3rem auto;
    display: grid;
  }

  .active:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .nav-links a:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .nav-btn-link {
    border-color: transparent;
    color: $yellow;
    cursor: pointer;
    background: transparent;
    border-radius: 4px;
    padding: 10px;
    gap: 10px;
    width: 150px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: center;
  }

  .nav-btn-link-2 {
    border: 1px solid $yellow;
    color: #1f1f28;
    cursor: pointer;
    background: $yellow;
    border-radius: 4px;
    padding: 10px;
    gap: 10px;
    width: 150px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: center;
  }
    .nav-btn-link-3 {
 display: flex;
width: 119px;
padding: 4px 10px;
justify-content: center;
align-items: center;
gap: 10px;

    border-radius: 4px;
   border: 1px solid var(--foundation-blue-secondary-text-400, #545455);
  }

      .nav-btn-link-4 {
display: flex;
width: 119px;
padding: 4px 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: var(--foundation-yellow-primary-500, #F1B807);
border: none;
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1f1f28;
  z-index: 2;
  // box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background: #333;
  transition: 0.4s;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.show-sidebar {
  right: 0;
}

.sidebar-menu {
  list-style: none;
  padding: 4rem 0 0 0;
  margin: 0;
  text-align: center;
}

.sidebar-menu li {
  padding: 15px;
  text-align: right;
}

.sidebar-menu a {
  font-size: 1rem;
  text-transform: capitalize;
  transition: var(--transition);
  color: $white;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: none;
  text-align: center;
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
}

.content-container {
  min-height: 500px;
  // margin: 0 1rem;

  // @media screen and (min-width: 992px) {
  //   margin: 0 2rem;
  // }
}
