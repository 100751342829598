//
// Text
//

// Text colors
@each $name, $color in $theme-text-colors {
  // Base color
  .text-#{$name} {
    color: $color !important;
  }

  // Hover
  .text-hover-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &:hover {
      transition: $transition-link;
      color: $color !important;

      i {
        transition: $transition-link;
        color: $color !important;
      }
    }
  }
}

// Text transparent
.text-transparent {
  color: transparent;
}

.fw-boldest {
  font-weight: $font-weight-boldest !important;
}

// Cursor pointer
.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}
// Cursor default
.cursor-default {
  cursor: default;
}
// Cursor move
.cursor-move {
  cursor: move;
}

.upppercaseText {
  text-transform: uppercase;
}
.capitaliseText {
  text-transform: capitalize;
}
.lowercaseText {
  text-transform: lowercase;
}
.pop {
  font-family: "Poppins", sans-serif;
}


@each $name, $color in $theme-text-colors {
  .underline-#{$name} {
    text-decoration-line: underline;
    color: $color !important;
  }
}
p {
  font-family: "Poppins";
}
