//
// Theme style
//

// Initialize
@import "init";

// Components
@import "components/custom/components.scss";
@import "components/components";

// Layout
@import "layout/layout";

@import url("../fonts/poppins.css");

html {
  scroll-behavior: smooth;
}
body {
  font-family: $font-family-sans-serif;
  background: $white;
  color: $white;
  line-height: $line-height-base;
  min-height: 100vh;
  font-weight: $font-weight-normal;
}


.icon {
  padding-left: 10px;
  padding-right: 20px;
  padding: 7px 20px 7px 10px;
  background: url("https://static.thenounproject.com/png/101791-200.png")
    no-repeat right;
  background-size: 20px;
  background-position-x: 95%;
  width: 250px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  outline: none;
  box-shadow: none;
}

.icon:focus-within,
.icon:focus,
.icon:active {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  outline: none;
  box-shadow: none;
}

.border-bottom {
  border-bottom: 1px solid #eaeaea;
}

.w-50 {
  width: 66%;
}

.w-100 {
  width: 100%;
}

.add-cta {
  border: 1px solid #f9de8d;
  box-shadow: 0px 4px 12px 0px #0000000f;
  outline: none !important;
  background: #f9de8d !important;
}