.body-dashboard {
  min-height: 500px;

  .title-body {
    // padding: 1rem;
    background-color: $dark;

    @media screen and (min-width: 992px) {
      // padding: 2rem;
    }
  }
  .title-body-text {
    margin: 0 auto;
    // width: 80%;

    @media screen and (min-width: 992px) {
      width: 49%;
      margin-top: 100px;
      margin-bottom: 25px;
    }

    .header-title {
      font-family: $font-family-sans-serif;
      font-size: 3rem;
      font-weight: 700;
      line-height: 3.125rem;
      letter-spacing: 0em;
      text-align: left;
      @media screen and (max-width: 992px) {
        font-size: 2.5rem;
        line-height: 2.75rem;
      }
    }

    .text-aling-header {
      text-transform: uppercase;
      text-wrap: nowrap;
    }

    .started-btn {
      border: 1px solid $yellow;
      color: #1f1f28;
      cursor: pointer;
      background: $yellow;
      border-radius: 0.25rem;
      padding: 0.625rem;
      width: 220px;
      //   height: 36px;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.219rem;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 32px;
      font-weight: 600;
      @media screen and (max-width: 992px) {
        font-size: 1rem;
        line-height: 0.719rem;
      }
    }
  }

  .title-body-text-2 {
    margin: 0 auto;
    text-align-last: center;
    // width: 80%;

    @media screen and (min-width: 992px) {
      width: 49%;
      margin-top: 100px;
      margin-bottom: 25px;
    }

    .header-title {
      font-family: $font-family-sans-serif;
      font-size: 3rem;
      font-weight: 700;
      line-height: 3.125rem;
      letter-spacing: 0em;
      text-align: left;
      @media screen and (max-width: 992px) {
        font-size: 2.5rem;
        line-height: 2.75rem;
      }
    }

    .text-aling-header {
      text-transform: uppercase;
      text-wrap: nowrap;
    }

    .started-btn {
      border: 1px solid $yellow;
      color: #1f1f28;
      cursor: pointer;
      background: $yellow;
      border-radius: 0.25rem;
      padding: 0.625rem;
      width: 220px;
      //   height: 36px;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.219rem;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 32px;
      font-weight: 600;
      @media screen and (max-width: 992px) {
        font-size: 1rem;
        line-height: 0.719rem;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .transImgbg {
      margin-top: 50px;
    }
  }

  .transactionPreviewImg {
    
    width: 100%;
    height: 100%;
    top: 206px;
    left: 850px;
  }

  .store {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    margin-top: 41px;
    margin-bottom: 99.83px;

    @media screen and (min-width: 992px) {
      width: 443px;
      height: 59.174px;
    }

    @media screen and (max-width: 540px) {
      width: 100%;
      justify-content: center;
    }
    .apple {
      width: 210.614px;
      height: 59.174px;
      margin-right: 22px;
      @media screen and (max-width: 540px) {
        width: 170.614px;
        height: 49.174px;
      }
    }
    .google {
      width: 210.61px;
      height: 59.174px;

      @media screen and (max-width: 540px) {
        width: 170.61px;
        height: 49.174px;
      }
    }
  }

  .tc {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    color: #d9d9d9;

    .highlightTc {
      color: $yellow;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 800;
      line-height: 1.625rem;
      text-decoration: none;
    }
  }
  .api-integration {
    color: $white;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px;
  }

  .child-write {
    position: absolute;
    right: 0;
  }

  .steps-container {
    padding: 102px 0;
    @media screen and (max-width: 991px) {
      padding: 5rem 0;
    }

    .title {
      color: $yellow;
      font-family: $font-family-sans-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.625rem;
      text-transform: uppercase;
    }
    .sub-title-container {
      margin-top: 57.04px;

      @media screen and (min-width: 992px) {
        width: 633px;
        margin-top: 57.04px;
      }
      .sub-title {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.219rem;
        text-transform: uppercase;
        color: $black;
        text-align: center;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      @media screen and (min-width: 992px) {
        flex-direction: row;
      }
    }

    .image {
      display: none;
      @media screen and (min-width: 992px) {
        display: block;
        width: 361.15px;
        height: 361.15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sub-details-container {
      width: 231px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container2 {
      width: 255.959px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container3 {
      width: 298px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details {
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #6e6e6e;
    }
    .steps-btn-container {
      margin: 71px 0;

      @media screen and (max-width: 991px) {
        margin: 2rem 0 0 0;
      }
    }
  }

  .features-container {
    padding: 188px 0;
    background-color: $dark;
    @media screen and (max-width: 991px) {
      padding: 5rem 1rem;
    }

    .title {
      color: $yellow;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.625rem;
      text-transform: uppercase;
      text-align: center;
    }

    .sub-title-container {
      margin-top: 11.77px;

      @media screen and (min-width: 992px) {
        width: 730px;
      }
      .sub-title {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.219rem;
        text-transform: uppercase;
        text-align: center;
        color: #f6f6f5;
      }
    }

    .feature-desc-container {
      margin-top: 6.01px;
      text-align: center;

      @media screen and (min-width: 992px) {
        width: 574.746px;
      }
      .feature-description {
        color: #a39696;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
      }
    }

    .features {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      @media screen and (min-width: 992px) {
        flex-direction: row;
      }
      .carousel-container {
        display: flex !important;
        margin: 0 0 2.5rem 1rem;

        @media screen and (min-width: 992px) {
          display: flex !important;
          margin: 0 149.37px 100px 168px;
          justify-content: space-between;
        }
      }

      .feature-carousel {
        flex: 0 0 30%;
        margin: 0 10px;
        padding: 20px;
        border-radius: 5px;
        text-align: left;
        @media screen and (max-width: 540px) {
          flex: unset;
          margin: 1rem 0;
          padding: 1rem 0;
          text-align: center;
        }

        .feature-title {
          color: $yellow;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 3.219rem;
          text-transform: uppercase;
        }

        .feature-description {
          color: #a6a6a6;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.375rem;
        }
        .feature-image {
          height: 50px;
          width: 51px;
          @media screen and (max-width: 540px) {
            width: unset;
          }
        }

        .feature-unavaliable {
          color: $white;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .benefits {
      color: $yellow;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.625rem;
      letter-spacing: 0em;
      text-align: left;
    }

    .benefits-list-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media screen and (min-width: 992px) {
        width: 632px;
      }
    }

    .sub-benefits {
      color: #fefefe;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.219rem;
      text-transform: uppercase;
      text-align: center;
    }
    .fileBroImage {
      width: 501px;
      height: 501px;
    }

    .image {
      display: none;
      @media screen and (min-width: 992px) {
        display: block;
        width: 361.15px;
        height: 361.15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sub-details-container {
      width: 231px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container2 {
      width: 255.959px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container3 {
      width: 298px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    @media screen and (max-width: 991px) {
      .middle-sm {
        justify-content: center;
        text-align: center;
      }
    }
    .sub-details {
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #6e6e6e;
    }
    .features-btn-container {
      margin: 53px 0 0 0;

      @media screen and (max-width: 991px) {
        margin: 2rem 0 0 0;
      }
    }
  }

  .features-container-2 {
    padding: 100px 0;
    background-color: #fff;
    @media screen and (max-width: 991px) {
      padding: 5rem 1rem;
    }

    .title {
      color: var(--foundation-blue-secondary-text-600, #252526);
      text-align: center;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }

    .sub-title-container {
      margin-top: 11.77px;

      @media screen and (min-width: 992px) {
        width: 730px;
      }
      .sub-title {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.219rem;
        text-transform: uppercase;
        text-align: center;
        color: #0d0c0c;
      }
    }

    .feature-desc-container {
      margin-top: 6.01px;
      text-align: center;

      @media screen and (min-width: 992px) {
        width: 574.746px;
      }
      .feature-description {
        color: #a39696;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
      }
    }

    .features {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      @media screen and (min-width: 992px) {
        flex-direction: row;
      }
    }

    .benefits {
      color: $yellow;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.625rem;
      letter-spacing: 0em;
      text-align: left;
    }

    .benefits-list-container {
      display: flex;
      margin-top: 4rem;
      // justify-content: center;
      flex-direction: column;
      @media screen and (min-width: 992px) {
        width: 632px;
      }
    }

    .middle-sm-txt {
      display: block;
      color: var(--foundation-blue-secondary-text-600, #252526);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 200% */
    }

    .sub-benefits {
      color: #fefefe;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.219rem;
      text-transform: uppercase;
      text-align: center;
    }
    .fileBroImage {
      width: 501px;
      height: 501px;
    }

    .image {
      display: none;
      @media screen and (min-width: 992px) {
        display: block;
        width: 361.15px;
        height: 361.15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sub-details-container {
      width: 231px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container2 {
      width: 255.959px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container3 {
      width: 298px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    @media screen and (max-width: 991px) {
      .middle-sm {
        justify-content: center;
        text-align: center;
      }
    }
    .sub-details {
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #6e6e6e;
    }
    .features-btn-container {
      margin: 53px 0 0 0;

      @media screen and (max-width: 991px) {
        margin: 2rem 0 0 0;
      }
    }
  }

  .started-btn {
    border: 1px solid $yellow;
    color: #1f1f28;
    cursor: pointer;
    background: $yellow;
    border-radius: 0.25rem;
    padding: 0.625rem;
    width: 220px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.219rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 600;
    height: 56px;

    @media screen and (max-width: 992px) {
      font-size: 1rem;
      line-height: 0.719rem;
    }
  }

  .mission-container {
    padding: 5rem 1rem;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 992px) {
      padding: 142.64px 142px 142.28px 141.52px;
    }

    .mission-title {
      color: $yellow;
      font-family: $font-family-sans-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.625rem;
      text-transform: uppercase;
    }
    .sub-mission-container {
      margin-top: 19.64px;

      @media screen and (min-width: 992px) {
        width: 543px;
      }
      .sub-mission {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.219rem;
        text-transform: uppercase;
        text-align: center;
        color: #0d0c0c;
      }
    }
    .mission-desc-container {
      margin-top: 11px;

      @media screen and (min-width: 992px) {
        width: 543px;
      }
      .mission-description {
        color: #615e5e;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.531rem;
      }
    }

    .mission-btn-container {
      margin: 31.9px 0;

      @media screen and (max-width: 991px) {
        margin: 1.5rem 0 0 0;
      }
    }

    .mission-image {
      margin-top: 71.78px;
    }

    .image {
      @media screen and (min-width: 992px) {
        width: 286.27px;
        height: 253px;
        margin-top: 56.36px;
      }
    }
  }

  .itm-cen {
    justify-content: center;
  }

  .text-din {
    color: var(--foundation-blue-secondary-text-400, #545455);
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
}

.carousel .control-dots .dot.selected {
  background-color: $yellow; /* Change to your desired active color */
}


  .features-container-3 {
    // padding: 100px 0;
    background-color: #fff;
    @media screen and (max-width: 991px) {
      // padding: 5rem 1rem;
    }

    .title {
      color: var(--foundation-blue-secondary-text-600, #252526);
      text-align: center;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }

    .sub-title-container {
      margin-top: 11.77px;

      @media screen and (min-width: 992px) {
        width: 730px;
      }
      .sub-title {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.219rem;
        text-transform: uppercase;
        text-align: center;
        color: #0d0c0c;
      }
    }

    .feature-desc-container {
      margin-top: 6.01px;
      text-align: center;

      @media screen and (min-width: 992px) {
        width: 574.746px;
      }
      .feature-description {
        color: #a39696;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
      }
    }

    .features {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      @media screen and (min-width: 992px) {
        flex-direction: row;
      }
    }

    .benefits {
      color: $yellow;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.625rem;
      letter-spacing: 0em;
      text-align: left;
    }

    .benefits-list-container {
      display: flex;
      margin-top: 4rem;
      // justify-content: center;
      flex-direction: column;
      @media screen and (min-width: 992px) {
        width: 632px;
      }
    }

    .middle-sm-txt {
      display: block;
      color: var(--foundation-blue-secondary-text-600, #252526);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 200% */
    }

    .sub-benefits {
      color: #fefefe;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.219rem;
      text-transform: uppercase;
      text-align: center;
    }
    .fileBroImage {
      width: 501px;
      height: 501px;
    }

    .image {
      display: none;
      @media screen and (min-width: 992px) {
        display: block;
        width: 361.15px;
        height: 361.15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sub-details-container {
      width: 231px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container2 {
      width: 255.959px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    .sub-details-container3 {
      width: 298px;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        width: unset;
      }
    }

    @media screen and (max-width: 991px) {
      .middle-sm {
        justify-content: center;
        text-align: center;
      }
    }
    .sub-details {
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #6e6e6e;
    }
    .features-btn-container {
      margin: 53px 0 0 0;

      @media screen and (max-width: 991px) {
        margin: 2rem 0 0 0;
      }
    }
  }

.faq-con{
  display: grid;
  color: #0d0c0c;
 grid-template-columns: 20% 80%;
 justify-content: center;
//  height: 100vh;



 .faq-side{
  display: flex;
  flex-direction: column;
  border-right: 1px solid #EAEAEA;
  padding: 50px 0;
  margin-left: 2rem;

  a{
    text-decoration: none;
  }

  span{
    display: flex;
padding: 10px 10px;
align-items: center;
gap: 10px;
color: var(--foundation-blue-secondary-text-500, #29292A);
text-align: center;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 200% */
margin-right: 2rem;
cursor: pointer;
white-space: nowrap;
  }

  .active{
background: var(--foundation-yellow-primary-50, #FEF8E6);

  }
 }

 .faq-main{
    display: flex;
  flex-direction: column;
  padding: 50px 0;
  margin-left: 4rem;
  width: 50%;

  input{
    color: var(--foundation-blue-secondary-text-200, #9D9D9D);
text-align: center;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 200% */
display: flex;
width: 304px;
padding: 10px;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 2px solid var(--foundation-blue-secondary-text-50, #EAEAEA);
  }

   .faq-body{
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  
 }

    .privacy-body{
  display: flex;
  flex-direction: column;
  
 }

 .faq-body-inner{
  display: flex;
  justify-content: space-between;
  // margin-top: 2rem;
border-top: 1px solid var(--foundation-blue-secondary-text-50, #EAEAEA); 
padding: 1rem;
}

.faq-body-inner-content{
  display: flex;
  padding: 1.5rem;
  color: var(--foundation-blue-secondary-text-400, #545455);
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 150% */
}

.faq-header{
  color: var(--foundation-blue-secondary-text-500, #29292A);
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
margin-left: 12px;
}

.privacy-list{
  display: flex;
  padding: 50 0;


  li {
      color: var(--foundation-blue-secondary-text-400, #545455);
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 150% */
padding: 5px;
  }

  span{
          color: var(--foundation-blue-secondary-text-400, #545455);
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 150% */
padding: 5px;
  }

}

 }




}


.header-con{
display: flex;
align-items: flex-start;
gap: 9px;
padding: 1rem;
border-bottom: 2px solid #EAEAEA;

}

.all-user-text{
  color: #484848;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.all-user-text-nude{
color: var(--foundation-blue-secondary-text-100, #BDBDBD);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.btn-root{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}



.btn-account-management{
  height: 48px;
padding: 5px 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 1px solid var(--foundation-yellow-primary-200, #F9DE8D);
background: var(--foundation-yellow-primary-50, #FEF8E6);

/* Btn_Shadow */
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}

.user-reg-con{
  display: flex;
flex-direction: column;
align-items: flex-end;
}


.user-reg-bold-text{
  color: var(--foundation-blue-secondary-text-400, #545455);
font-family: 'Poppins';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 125% */
}

.user-reg-text{
  color: var(--foundation-blue-secondary-text-400, #545455);
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

/* CustomSwitch.css */
.custom-switch {
  background-color: "red"; /* Set your default background color */
}

.custom-switch:checked {
  background-color: "green"; /* Set your checked background color */
}

.defualt{
  display: flex;
width: 80px;
height: 18px;
padding: 0px 2px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;

border-radius: 4px;
background: var(--orange, #FFAE11);
}

.role-con{
  display: flex;
  // justify-content: space-around;
}

.icon-con{
  margin-left: 0.5rem;
}


.verifiedtext{
  display: flex;
  width: 100%;
}
