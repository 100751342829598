.otp-box {
  display: flex;
}

.otp-box input {
  width: 46px !important;
  height: 46px;
  font-size: 1rem;
  padding: 10px;
  margin: 0 6px;
  border-radius: 10px;
  border: 1px solid #aca9a8;
  background: #f9f9f9;

  color: $text-black;
  outline: none;
  transition: all 0.1s;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%;
  /* or 20px */

  letter-spacing: 0.2px;
}

.enter-otp {
  color: #484848;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .done-btn {
    width: 197px;
    height: 46px;
    margin: 36px 0;
  }
  .resend-text {
    color: #f1b807;
    text-align: center;
    font-family: Poppins;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    cursor: pointer;
  }

  .resent-text-inactive{
     cursor: none;
  }
}
