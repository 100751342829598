.ant-form-item {
  font-family: $font-family-sans-serif !important ;
}
.ant-form-item-label {
  color: #484848;
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ant-input {
  background: #f9f9f9 !important;
  font-family: $font-family-sans-serif !important ;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid #aca9a8;
  background: #f9f9f9 !important;
  height: 45px;

  &:hover {
    border: 1px solid #373737 !important;
  }
}

.ant-form-item .ant-form-item-explain-error {
  font-family: $font-family-sans-serif !important ;
}

.ant-btn {
  border-radius: 4px;
  background-color: #f1b900;
  padding: 10px;
  color: #1d1b1b;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  height: auto;
  border: none !important;

  svg {
    path {
      fill: black !important;
    }
  }

  &:hover,
  &:active {
    background-color: #dfac00 !important;
    color: #1d1b1b !important;
    outline: none !important;
    border: none !important;

    &:disabled {
      background-color: #f9e296 !important;
      color: #fef8e6 !important;
    }
  }

  &:disabled {
    background: #f9e296;
    color: #fef8e6;

    svg {
      path {
        fill: rgb(254, 248, 230) !important;
      }
    }
  }
}

// .ant-checkbox-wrapper
// hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1vr7spz).
// ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inne

.ant-checkbox .ant-checkbox-inner {
  ::after {
    border: 2px solid brown !important;
  }
}

.ant-checkbox-wrapper .ant-checkbox {
  background-color: #fff;
  border: 1.5px solid #a4a4a6 !important;
  outline: none !important;
  border: none !important;

  .ant-checkbox-inner {
    width: 0.938rem !important;
    height: 0.938rem !important;
    border: 1.5px solid #a4a4a6 !important;
    ::after {
      border: 2px solid green !important;
    }
  }
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #f2b807 !important ;
  background-color: #f2b807 !important;
  width: 0.938rem !important;
  height: 0.938rem !important;
  &:after {
    border: 2px solid #292D32;
    border-top: 0;
    border-inline-start: 0;
  }
}
